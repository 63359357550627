import React, { Component } from "react";

import Routes from "./Routes";
/// test
import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { USER_KEY } from "./consts/user";

library.add(fab, fas);
dom.watch();
// test ddddd d

const client = new ApolloClient({
  //uri: "https://nuol-adocker ipi.lailaolab.com/",
  // uri: "http://13.214.171.46:7070/", //DB staging
  // uri: "https://7nhp7tu4jk.execute-api.ap-southeast-1.amazonaws.com/",
  //uri: "http://localhost:7070/",
  uri: "https://api.office.nuol.edu.la/",
  // uri: "http://18.143.161.41",
  // uri: "http://13.251.5.178:2001/",
//  uri: "http://13.229.141.85:7071",

  //  uri: "http://nuol-loadbalancer-747438618.ap-southeast-1.elb.amazonaws.com",
  //uri: "https://api.office.nuol.edu.la/",

  request: (operation) => {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      const token = JSON.parse(user)["accessToken"];
      operation.setContext({
        headers: {
          Authorization: token ? "Nuol " + token : "",
          Platform: "STUDENT",
        },
      });
      return;
    }
    operation.setContext({
      headers: {
        Platform: "STUDENT",
      },
    });
  },
  onError: (error) => {
    if (error?.response?.errors?.length > 0) {
      if (
        error?.response?.errors[0]?.message === "TokenExpiredError: jwt expired"
      ) {
        localStorage.clear();
      }
    }
    console.log("error: ", error?.response?.errors[0]?.message);
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    );
  }
}

export default App;
